import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// eslint-disable-next-line import/no-named-as-default-member
i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    backendOptions: { defaultVersion: 'v' + new Date().getTime() },
    ns: ['translation'],
    defaultNS: 'translation',
    fallbackLng: 'en-US',
    load: 'currentOnly',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format) => {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      },
    },
  });

export default i18n;

export const switchLanguage = () => {
  const currentLanguage = localStorage.getItem('i18nextLng');
  const newLanguage = currentLanguage === 'en-US' ? 'en-US' : 'en-US';
  localStorage.setItem('i18nextLng', newLanguage);
  document.location.reload();
};
