import { useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Icon, Logo } from '~/components/base';

import { AppBarContainer, StyledHeader, StyledToolbar } from './AppBar.Style';
import DesktopMenu from './DesktopMenu';
import MobileDrawer from './MobileDrawer';

const CustomAppBar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <AppBarContainer>
      <StyledToolbar>
        <StyledHeader>
          {isDownMd ? (
            <>
              <Box sx={{ alignItems: 'center', display: 'flex', width: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexGrow: 0,
                    justifyContent: 'flex-start',
                  }}
                >
                  <Logo height={32} width={165} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'flex-end',
                  }}
                >
                  <Icon
                    alt={'menu-icon'}
                    name={isDrawerOpen ? 'icons/close' : 'icons/menu'}
                    height={24}
                    onClick={isDrawerOpen ? handleCloseDrawer : handleOpenDrawer}
                    width={24}
                  />
                </Box>
              </Box>

              <MobileDrawer isOpen={isDrawerOpen} onClose={handleCloseDrawer} />
            </>
          ) : (
            <Box sx={{ alignItems: 'center', display: 'flex', width: '100%' }}>
              <Box sx={{ display: 'flex', flexGrow: 0, mr: 3 }}>
                <Logo height={38} width={196} />
              </Box>
              <Box sx={{ alignItems: 'center', display: 'flex', flexGrow: 1 }}>
                <DesktopMenu />
              </Box>
            </Box>
          )}
        </StyledHeader>
      </StyledToolbar>
    </AppBarContainer>
  );
};

export default CustomAppBar;
