import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/system';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  backgroundColor: theme.palette.background.paper,
  paddingBottom: 56,
  top: 56,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  width: drawerWidth,
});

const closedMixin = (theme) => ({
  backgroundColor: theme.palette.background.paper,
  paddingBottom: 56,
  top: 56,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: 0,
});

export const DrawerContainer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => ![].includes(prop),
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
