import {
  filledInputClasses,
  formHelperTextClasses,
  formLabelClasses,
  inputBaseClasses,
  inputClasses,
  inputLabelClasses,
  outlinedInputClasses,
  TextField as MuiTextField,
} from '@mui/material';
import { styled } from '@mui/system';

import { Typography } from '..';

export const StyledDiv = styled('div', {
  shouldForwardProp: (propName) => !['containerStyle', 'fullWidth'].includes(propName),
})(({ theme, containerStyle, fullWidth }) => {
  return {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    maxWidth: fullWidth ? 'unset' : 500,
    minHeight: 30,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      maxWidth: 'unset',
      width: '100%',
    },
    ...containerStyle,
  };
});
export const StyledLabel = styled((props) => <Typography component="label" {...props} />, {
  shouldForwardProp: (propName) => !['labelStyle'].includes(propName),
})(({ theme, labelStyle }) => {
  return {
    alignItems: 'center',
    alignSelf: 'flex-start',
    color: theme.palette.text.primary,
    display: 'inline-flex',
    fontSize: 14,
    justifyContent: 'flex-start',
    margin: 0,
    minWidth: 100,
    paddingBottom: 8,
    textAlign: 'left',
    verticalAlign: 'middle',
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      display: 'block',
      margin: '10px 0 0 0',
    },
    ...labelStyle,
  };
});

const CUSTOM_PROP_NAMES = ['containerStyle', 'inputStyle'];
const shouldForwardProp = (prop) => !CUSTOM_PROP_NAMES.includes(prop);

export const StyledTextField = styled(MuiTextField, { shouldForwardProp })(({
  theme,
  disabled,
  inputStyle,
  labelStyle,
}) => {
  return {
    background: 'transparent',
    flex: 1,
    fontSize: 14,
    justifyContent: 'center',
    borderRadius: 8,
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
    },

    [`& .${inputLabelClasses.root}`]: {
      alignItems: 'center',
      color: theme.palette.text.primary,
      display: 'inline-flex',
      fontSize: 14,
      margin: 0,
      minWidth: 100,
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        flexBasis: '100%',
        display: 'block',
        margin: '10px 0 0 0',
      },
      ...labelStyle,
    },

    [`& .${formLabelClasses.asterisk}`]: {
      color: theme.palette.error.main,
    },
    [`& .${inputClasses.underline}`]: {
      cursor: disabled ? 'not-allowed' : 'text',
      '&::before': {
        borderBottomWidth: 1,
      },
    },

    [`& .${outlinedInputClasses.root}`]: {
      borderRadius: 8,
      background: theme.palette.background.paper,
    },
    [`& .${formHelperTextClasses.root}`]: {
      marginLeft: 0,
    },

    [`& .${inputClasses.input}`]: {
      paddingLeft: 10,
      cursor: disabled ? 'not-allowed' : 'text',
      ...inputStyle,
    },

    [`& .${inputBaseClasses.input}`]: {
      cursor: disabled ? 'not-allowed' : 'text',
      ...inputStyle,
    },

    [`& .${filledInputClasses.root}`]: {
      backgroundColor: 'inherit',
      cursor: disabled ? 'not-allowed' : 'text',
    },

    [`& .${inputClasses.root}`]: {
      backgroundColor: 'inherit',
      minHeight: 32,
    },
  };
});
