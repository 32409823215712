import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Typography } from '~/components/base';
import HomeHero from '~/components/fullComponent/Sections/HomeHero';
import InformationType1 from '~/components/fullComponent/Sections/InformationType1';
import InformationType2 from '~/components/fullComponent/Sections/InformationType2';
import InformationType3 from '~/components/fullComponent/Sections/InformationType3';
import InformationType4 from '~/components/fullComponent/Sections/InformationType4';
import InformationType5 from '~/components/fullComponent/Sections/InformationType5';
import InformationType6 from '~/components/fullComponent/Sections/InformationType6';

const HomePage = () => {
  const { t } = useTranslation();

  const withBullets = (text) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            mr: 1,
            p: '3px',
            width: 10,
            height: 10,
            bgcolor: '#4A67FF',
            borderRadius: '50%',
          }}
        />
        <Typography variant="h4">{text}</Typography>
      </Box>
    );
  };

  return (
    <>
      <div id="contact-us" />
      <HomeHero />
      <InformationType1 />
      <div id="discover" />
      <InformationType2 />
      <InformationType3
        content={
          <Box sx={{ width: '100%' }}>
            {withBullets(t('home.InformationType3_1.title1'))}
            <Typography>{t('home.InformationType3_1.description1')}</Typography>

            {withBullets(t('home.InformationType3_1.title2'))}
            <Typography>{t('home.InformationType3_1.description2')}</Typography>

            {withBullets(t('home.InformationType3_1.title3'))}
            <Typography>{t('home.InformationType3_1.description3')}</Typography>
          </Box>
        }
        image="flexibleDesign"
        imagePosition="left"
        imageStyle={{ objectPosition: 'top' }}
        title={t('home.InformationType3_1.title')}
      />
      <InformationType3
        content={
          <Box sx={{ width: '100%' }}>
            {withBullets(t('home.InformationType3_2.title1'))}
            <Typography>{t('home.InformationType3_2.description1')}</Typography>

            {withBullets(t('home.InformationType3_2.title2'))}
            <Typography>{t('home.InformationType3_2.description2')}</Typography>

            {withBullets(t('home.InformationType3_2.title3'))}
            <Typography>{t('home.InformationType3_2.description3')}</Typography>
          </Box>
        }
        image="clinician"
        imagePosition="right"
        imageStyle={{ objectPosition: 'top' }}
        title={t('home.InformationType3_2.title')}
      />
      <InformationType3
        content={
          <Box sx={{ width: '100%' }}>
            {withBullets(t('home.InformationType3_3.title1'))}
            <Typography>{t('home.InformationType3_3.description1')}</Typography>

            {withBullets(t('home.InformationType3_3.title2'))}
            <Typography>{t('home.InformationType3_3.description2')}</Typography>
          </Box>
        }
        image="twoWayCommunication"
        imagePosition="left"
        imageStyle={{ objectPosition: 'top' }}
        title={t('home.InformationType3_3.title')}
      />
      <div id="benefits" />
      <InformationType4 />
      <InformationType5 />
      <div id="about-us" />
      <InformationType6 />
    </>
  );
};

export default HomePage;
