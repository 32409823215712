import { create } from 'zustand';

export const useSnackbar = create((set) => ({
  snackbarConfig: {
    open: false,
    text: '',
    description: '',
    severity: 'success',
  },
  showSnackbar: (config) => set({ snackbarConfig: { ...config, open: true } }),
  hideSnackbar: (config) => set({ snackbarConfig: { ...config, open: false } }),
}));
