import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

import { Icon, Typography } from '~/components/base';

const InformationType4 = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.background.lightBlue,
        p: { xs: '24px 20px', sm: '48px 20px', md: '80px 48px' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ maxWidth: 1440 }}>
        <Typography sx={{ fontSize: 54, fontWeight: 800, textAlign: 'center' }} variant="h2">
          {t('home.InformationType4.title')}
        </Typography>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 1.5,
            }}
          >
            <Icon alt={'time-icon'} name={'icons/time'} height={48} width={48} />
            <Typography sx={{ textAlign: 'center' }} variant="h3">
              {t('home.InformationType4.title1')}
            </Typography>
            <Typography sx={{ textAlign: 'center' }}>
              {t('home.InformationType4.description1')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 1.5,
            }}
          >
            <Icon alt={'HIPAA-icon'} name={'icons/HIPAA'} height={48} width={48} />
            <Typography sx={{ textAlign: 'center' }} variant="h3">
              {t('home.InformationType4.title2')}
            </Typography>
            <Typography sx={{ textAlign: 'center' }}>
              {t('home.InformationType4.description2')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 1.5,
            }}
          >
            <Icon alt={'interoperable-icon'} name={'icons/interoperable'} height={48} width={48} />
            <Typography sx={{ textAlign: 'center' }} variant="h3">
              {t('home.InformationType4.title3')}
            </Typography>
            <Typography sx={{ textAlign: 'center' }}>
              {t('home.InformationType4.description3')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 1.5,
            }}
          >
            <Icon alt={'unmatched-icon'} name={'icons/unmatched'} height={48} width={48} />
            <Typography sx={{ textAlign: 'center' }} variant="h3">
              {t('home.InformationType4.title4')}
            </Typography>
            <Typography sx={{ textAlign: 'center' }}>
              {t('home.InformationType4.description4')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 1.5,
            }}
          >
            <Icon alt={'IRB-icon'} name={'icons/IRB'} height={48} width={48} />
            <Typography sx={{ textAlign: 'center' }} variant="h3">
              {t('home.InformationType4.title5')}
            </Typography>
            <Typography sx={{ textAlign: 'center' }}>
              {t('home.InformationType4.description5')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 1.5,
            }}
          >
            <Icon alt={'quick-icon'} name={'icons/quick'} height={48} width={48} />
            <Typography sx={{ textAlign: 'center' }} variant="h3">
              {t('home.InformationType4.title6')}
            </Typography>
            <Typography sx={{ textAlign: 'center' }}>
              {t('home.InformationType4.description6')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default InformationType4;
