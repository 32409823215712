import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { CircularProgress, Typography } from '~/components/base';
import ThemeContextProvider from '~/components/theme';

const StyledPaper = styled(Paper)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  width: '100%',
}));

const LoadingFallbackScreen = ({ message }) => {
  return (
    <ThemeContextProvider>
      <StyledPaper component="main">
        <CircularProgress sx={{ mr: 2 }} />
        {message && <Typography component="span">{message}</Typography>}
      </StyledPaper>
    </ThemeContextProvider>
  );
};

LoadingFallbackScreen.propTypes = {
  message: PropTypes.node,
};

export default LoadingFallbackScreen;
