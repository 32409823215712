import clsx from 'clsx';
import PropTypes from 'prop-types';

import { StyledTypography } from './Typography.Style';

const TypographyPropTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

/** @type {React.ForwardRefRenderFunction<?, import('@mui/material').TypographyProps & TypographyPropTypes>} */
const Typography = ({ children, className, ...restProps }) => {
  return (
    <StyledTypography className={clsx(className)} {...restProps}>
      {children}
    </StyledTypography>
  );
};

Typography.propTypes = TypographyPropTypes;
export default Typography;
