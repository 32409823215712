import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import LoadingFallbackScreen from '~/components/layout/loadingFallbackScreen';
import SnackBarContainer from '~/components/layout/snackbarContainer';
import ThemeContextProvider from '~/components/theme';
import router from '~/router';

const App = () => {
  return (
    <Suspense fallback={<LoadingFallbackScreen />}>
      <ThemeContextProvider>
        <CssBaseline />
        <RouterProvider router={router} />
        <SnackBarContainer />
      </ThemeContextProvider>
    </Suspense>
  );
};

export default App;
