import { Box, Toolbar } from '@mui/material';
import { styled } from '@mui/system';

export const AppBarContainer = styled(Box, {
  shouldForwardProp: (prop) => ![].includes(prop),
})(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
  position: 'fixed',
  top: 0,
  transition: theme.transitions.create(['width', 'height', 'margin'], {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: '100%',
  zIndex: theme.zIndex.appBar,
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  height: 64,
  minHeight: '64px !important',
  padding: '0 !important',

  [theme.breakpoints.down('md')]: {
    height: 56,
    minHeight: '56px !important',
  },
}));

export const StyledHeader = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: 64,
  minHeight: '64px !important',
  padding: '12px 48px',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    height: 56,
    minHeight: '56px !important',
    padding: '12px 20px',
  },
}));
