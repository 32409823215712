import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledFooter = styled(Box, {
  shouldForwardProp: (prop) => ![].includes(prop),
})(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.white,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: '80px 48px',

  [theme.breakpoints.down('md')]: {
    backgroundColor: theme.palette.background.lightBlue,
    padding: '24px 0',
  },
}));
