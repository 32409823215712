import { Box, Divider, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { NextImage, Typography } from '~/components/base';

const InformationType3PropType = {
  content: PropTypes.any,
  image: PropTypes.string,
  imagePosition: PropTypes.string,
  imageStyle: PropTypes.object,
  title: PropTypes.string,
};

const InformationType3 = ({ content, image, imagePosition, imageStyle, title }) => {
  const isImageLeft = imagePosition === 'left';

  return (
    <Box
      sx={{
        p: { xs: '24px 20px', md: '128px 162px' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ maxWidth: 1440 }}>
        <Typography
          sx={{
            display: { xs: 'block', md: 'none' },
            color: '#4A67FF',
            width: '100%',
            mb: 3,
          }}
          variant="h3"
        >
          {title}
        </Typography>
        <Grid container flexDirection={isImageLeft ? 'row' : 'row-reverse'}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              pr: isImageLeft ? { xs: 0, md: 9 } : 0,
              pl: isImageLeft ? 0 : { xs: 0, md: 9 },
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                height: { xs: 223, md: 432 },
                width: { xs: '100%', md: 432 },
                maxWidth: '100%',
                borderRadius: '24px',
                overflow: 'hidden',
                my: { xs: 3, md: 0 },
              }}
            >
              <NextImage alt={image} height="100%" name={image} style={imageStyle} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              pr: isImageLeft ? 0 : { xs: 0, md: 9 },
              pl: isImageLeft ? { xs: 0, md: 9 } : 0,
              justifyContent: {
                xs: 'center',
                md: isImageLeft ? 'flex-start' : 'flex-end',
              },
            }}
          >
            <Typography
              sx={{
                display: { xs: 'none', md: 'block' },
                color: '#4A67FF',
                width: '100%',
              }}
              variant="h3"
            >
              {title}
            </Typography>
            <Divider
              sx={{
                display: { xs: 'none', md: 'block' },
                my: 1.5,
                width: '100%',
              }}
              style={{ backgroundColor: '#A1B0FF' }}
            />
            {content}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

InformationType3.propTypes = InformationType3PropType;
export default InformationType3;
