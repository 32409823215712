import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { NextImage, Typography } from '~/components/base';

const InformationType5 = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        p: { xs: '48px 20px', md: '80px 48px' },
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          height: { xs: 308, md: 660 },
          width: { xs: 308, md: 660 },
          left: { xs: -150, md: -330 },
          top: {
            xs: 'calc(50% - 308px/2 - 80px)',
            md: 'calc(50% - 660px/2 - 177.53px)',
          },
          background:
            'radial-gradient(50% 50% at 50% 50%, #E9F9E9 0%, rgba(217, 217, 217, 0) 100%)',
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          height: { xs: 308, md: 660 },
          width: { xs: 308, md: 660 },
          right: { xs: -150, md: -330 },
          bottom: {
            xs: 'calc(50% - 308px/2 - 80px)',
            md: 'calc(50% - 660px/2 - 177.53px)',
          },
          background:
            'radial-gradient(50% 50% at 50% 50%, #E9F9E9 0%, rgba(217, 217, 217, 0) 100%)',
          zIndex: -1,
        }}
      />

      <Box sx={{ maxWidth: 1440 }}>
        <Typography
          sx={{
            textAlign: 'center',
            mb: 6,
          }}
          variant="h3"
        >
          {t('home.InformationType5.title')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '40px',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              height: { xs: 95, md: 117 },
              width: { xs: 109, md: 118 },
            }}
          >
            <NextImage alt="NSF" height="100%" name="NSF" />
          </Box>
          <Box
            sx={{
              position: 'relative',
              height: { xs: 87, md: 103 },
              width: { xs: 99, md: 117 },
            }}
          >
            <NextImage alt="NIH" height="100%" name="NIH" />
          </Box>
          <Box
            sx={{
              position: 'relative',
              height: { xs: 79, md: 102 },
              width: { xs: 80, md: 102 },
            }}
          >
            <NextImage alt="IRVINE" height="100%" name="IRVINE" />
          </Box>
          <Box
            sx={{
              position: 'relative',
              height: { xs: 67, md: 75 },
              width: { xs: 184, md: 205 },
            }}
          >
            <NextImage alt="NOYCE" height="100%" name="NOYCE" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InformationType5;
