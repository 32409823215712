import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { Typography } from '~/components/base';
import errorImg from '~/resources/images/500.gif';

import { useStyles } from './NotFound.Styles';

const NotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    document.title = t('error-page.title');
  }, []);

  const isDownMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className={classes.wrapper} style={{ flexDirection: isDownMD ? 'column-reverse' : 'row' }}>
      <Box className={classes.descriptionContainer}>
        <Typography variant="h4" component="h1" gutterBottom>
          {t('error-page.heading')}
        </Typography>
        <Typography className={classes.textStyle}>{t('error-page.description')}</Typography>
        <ul className={classes.itemsContainer}>
          <Box className={classes.itemContainer}>
            <Typography component="li" className={classes.item}>
              {t('error-page.reason-one')}
            </Typography>
          </Box>
          <Box className={classes.itemContainer}>
            <Typography component="li" className={classes.item}>
              {t('error-page.reason-two')}
            </Typography>
          </Box>
          <Box className={classes.itemContainer}>
            <Typography component="li" className={classes.item}>
              {t('error-page.reason-three')}
            </Typography>
          </Box>
          <Box className={classes.itemContainer}>
            <Typography component="li" className={classes.item}>
              {t('error-page.reason-four')}
            </Typography>
          </Box>
          <Box className={classes.itemContainer}>
            <Typography component="li" className={classes.item}>
              {t('error-page.reason-five')}
            </Typography>
          </Box>
        </ul>
      </Box>
      <img alt="500" src={errorImg} style={{ width: 400, marginTop: 32, maxWidth: '60%' }} />
    </Box>
  );
};

export default NotFound;
