import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const NextImagePropTypes = {
  height: PropTypes.any,
  name: PropTypes.string,
  style: PropTypes.any,
  sx: PropTypes.any,
};

const NextImage = ({ height = '100%', name, style = {}, sx = {}, ...restProps }) => {
  return (
    <Box sx={{ height, width: '100%', ...sx }}>
      <img
        alt={restProps.alt}
        src={`/images/${name}.png`}
        style={{
          maxWidth: '100%',
          objectFit: 'cover',
          height,
          width: '100%',
          ...style,
        }}
        {...restProps}
      />
    </Box>
  );
};

NextImage.propTypes = NextImagePropTypes;
export default NextImage;
