import _ from 'lodash';

import axiosInstance from './http';

let postAbortControllers = [];

const abortPreviousRequests = (controllers, url) => {
  if (controllers.length) {
    for (const controller of controllers) {
      if (controller.url === url) {
        try {
          // controller.controller.abort();
        } catch (error) {}
      }
    }
    controllers = _.reject(controllers, (i) => i.url === url);
  }
};

export const post = async (url, body) => {
  abortPreviousRequests(postAbortControllers, url);
  const controller = new AbortController();
  postAbortControllers.push({ controller, url });

  try {
    const options = { signal: controller.signal };

    const { data } = await axiosInstance.post(url, body, options);

    return data;
  } catch (error) {
    if (error.code === 'ERR_CANCELED') return null;
    else throw new Error(error?.response?.data?.message);
  }
};
