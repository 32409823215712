export const pages = (t, path) => [
  ...(path === '/'
    ? [
        {
          id: 10,
          title: t('navigation.discover'),
          url: `#discover`,
        },
        {
          id: 20,
          title: t('navigation.benefits'),
          url: `#benefits`,
        },
        {
          id: 30,
          title: t('navigation.aboutUs'),
          url: `#about-us`,
        },
      ]
    : []),
  {
    id: 40,
    title: t('navigation.privacy'),
    url: `privacy`,
  },
];
