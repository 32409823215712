import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

import { NextImage, Typography } from '~/components/base';

import RequestDemo from '../RequestDemo';

const HomeHero = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        px: { xs: '20px', md: 6 },
        py: { xs: 3, sm: '40px', md: 10 },
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: { xs: '-9%', sm: -550, md: 0 },
          left: 0,
          width: '100%',
          height: '100%',
          background: 'Url(/images/svgs/hero-background.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          zIndex: -1,
        }}
      />
      <Grid container spacing={3} sx={{ maxWidth: 1440 }}>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-end' },
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: 'fit-content',
              height: 'fit-content',
              pr: { xs: 0, md: 12 },
              maxWidth: '100%',
            }}
          >
            <Typography
              sx={{
                textAlign: { xs: 'center', md: 'left' },
              }}
              variant="h1"
            >
              {t('home.title')}
            </Typography>
            <Typography
              sx={{
                textAlign: { xs: 'center', md: 'left' },
                color: '#4A67FF',
                mt: 1,
              }}
              variant="subtitle1"
            >
              {t('home.description')}
            </Typography>
            <Box
              sx={{
                position: 'relative',
                height: { xs: 346, md: 468 },
                width: { xs: '100%', md: 668 },
                maxWidth: '100%',
                borderRadius: '234px',
                overflow: 'hidden',
                mt: 3,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background:
                    'linear-gradient(90deg, rgba(255, 255, 255, 0) 37.65%, rgba(255, 255, 255, 0.5) 77.92%)',
                  zIndex: 1,
                }}
              />
              <NextImage alt="home-hero" height="100%" name="homeHero" />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: { xs: 'center', md: 'flex-start' },
            mt: { xs: 3, md: 0 },
          }}
        >
          <Typography
            sx={{
              textAlign: { xs: 'center', md: 'left' },
              py: 3,
              bgcolor: (theme) => ({
                xs: theme.palette.background.lightBlue,
                md: 'transparent',
              }),
            }}
            variant="h4"
          >
            {t('home.requestDemo')}
          </Typography>
          <Box
            sx={{
              bgcolor: (theme) => ({
                xs: theme.palette.background.lightBlue,
                md: 'transparent',
              }),
            }}
          >
            <RequestDemo />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeHero;
