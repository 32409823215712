// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  text-decoration: none;
  color: initial;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: #d6dee1;
  border: 6px solid transparent;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,4BAA4B;EAC5B,yBAAyB;EACzB,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["a {\n  text-decoration: none;\n  color: initial;\n}\n\n::-webkit-scrollbar {\n  width: 20px;\n}\n\n::-webkit-scrollbar-track {\n  background-color: transparent;\n}\n\n::-webkit-scrollbar-thumb {\n  background-clip: content-box;\n  background-color: #d6dee1;\n  border: 6px solid transparent;\n  border-radius: 20px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background-color: #a8bbbf;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
