import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';

import { NextImage, Typography } from '~/components/base';

const InformationType6 = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.background.green,
        p: { xs: '24px 20px', sm: '48px 20px', md: '128px 162px' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ maxWidth: 1440 }}>
        <Typography variant="h2">{t('home.InformationType6.title')}</Typography>
        <Typography sx={{ color: '#0875D9', mt: 1.5 }} variant="h3">
          {t('home.InformationType6.subtitle')}
        </Typography>
        <Divider sx={{ width: '100%', my: 1.5 }} style={{ backgroundColor: '#A1B0FF' }} />
        <Typography gutterBottom>{t('home.InformationType6.description1')}</Typography>
        <Typography gutterBottom>{t('home.InformationType6.description2')}</Typography>
        <Typography gutterBottom>{t('home.InformationType6.description3')}</Typography>
        <Typography gutterBottom>{t('home.InformationType6.description4')}</Typography>

        <Typography sx={{ mt: 1.5, mb: 1 }} variant="h3">
          {t('home.InformationType6.partnerTitle')}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '40px',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              height: { xs: 118, md: 164 },
              width: { xs: 118, md: 164 },
            }}
          >
            <NextImage alt="IRVINE" height="100%" name="IRVINE" />
          </Box>
          <Box
            sx={{
              position: 'relative',
              height: { xs: 50, md: 86 },
              width: { xs: 132, md: 229 },
            }}
          >
            <NextImage alt="IFH" height="100%" name="IFH" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InformationType6;
