import { Icon } from '..';

const Logo = (props) => {
  return (
    <a href={`/`} style={{ alignItems: 'center', display: 'flex' }}>
      <Icon alt="logo" name="text-logo" {...props} />
    </a>
  );
};

export default Logo;
