import PropTypes from 'prop-types';

const IconPropTypes = {
  alt: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.number,
};

const Icon = ({ alt, name, width, ...restProps }) => (
  <img alt={alt} src={`/images/svgs/${name}.svg`} width={width} {...restProps} />
);

Icon.propTypes = IconPropTypes;
export default Icon;
