import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px',
  },
  itemContainer: {
    display: 'list-item',
    textAlign: 'justify',
    marginTop: 8,
  },
  itemsContainer: {
    listStyleType: 'disc',
  },
  textStyle: {
    display: 'block',
    marginBlockEnd: '1em',
    marginBlockStart: '1em',
    marginInlineEnd: '0px',
    marginInlineStart: '0px',
    textAlign: 'justify',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
  },
}));
