import { Button, buttonClasses, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

import { Typography } from '..';

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => !['hasIcon', 'labelStyle', 'containerStyle'].includes(prop),
})(({ theme, hasIcon, labelStyle, containerStyle }) => {
  return {
    height: 48,
    borderRadius: 12,
    boxShadow: 'none',
    ...containerStyle,

    ':hover': {
      boxShadow: 'none',
    },

    color: labelStyle?.color,
    [`& .${buttonClasses.containedPrimary}`]: {
      color: labelStyle?.color || theme.palette.primary.contrastText,
    },

    [`&.${buttonClasses.disabled}.${buttonClasses.containedPrimary}`]: {
      backgroundColor: theme.palette.primary.main,
      color: labelStyle?.color || theme.palette.primary.contrastText,
      opacity: 0.5,
    },
  };
});

export const StyledTypography = styled((props) => <Typography component="label" {...props} />)(
  ({ theme, ...props }) => ({
    cursor: 'inherit',
    fontWeight: 'inherit',
    whiteSpace: 'nowrap',
  }),
);

export const StyledCircularProgress = styled(CircularProgress, {
  shouldForwardProp: (prop) => !['labelStyle', 'variant'].includes(prop),
})(({ theme, labelStyle, variant }) => {
  return {
    color:
      labelStyle?.color || variant === 'contained' ? theme.palette.primary.contrastText : 'primary',
  };
});
