import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, ListItemButton } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '~/components/base';

import { pages } from './helper';
import { DrawerContainer } from './MobileDrawer.Style';

const MobileDrawerPropTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

const MobileDrawer = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const path = window.location.pathname;

  const renderLink = (page) => (
    <a href={page.url} key={page.id} onClick={onClose}>
      <ListItem disablePadding>
        <ListItemButton sx={{ minHeight: 48, px: 1.5, py: 1 }}>
          <Typography sx={{ color: '#032F58', my: 0.5 }}>{page.title}</Typography>
        </ListItemButton>
      </ListItem>
    </a>
  );

  return (
    <DrawerContainer open={isOpen} onClose={onClose}>
      <nav>
        <Box sx={{ width: 240 }} role="presentation">
          <List>{pages(t, path).map((page) => renderLink(page))}</List>
        </Box>
      </nav>
    </DrawerContainer>
  );
};

MobileDrawer.propTypes = MobileDrawerPropTypes;
export default MobileDrawer;
