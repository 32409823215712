import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Typography } from '~/components/base';

import { pages } from './helper';

const DesktopMenu = () => {
  const { t } = useTranslation();
  const path = window.location.pathname;

  const renderLink = (page) => (
    <a href={page.url} key={page.id}>
      <Typography sx={{ color: '#032F58' }}>{page.title}</Typography>
    </a>
  );

  return (
    <nav>
      <Box alignItems="center" display="flex" gap={3}>
        {pages(t, path).map((page) => renderLink(page))}
      </Box>
    </nav>
  );
};

export default DesktopMenu;
