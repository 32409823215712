import { Outlet } from 'react-router-dom';

import CustomAppBar from '../appBar';
import CustomContainer from '../container';
import Footer from '../footer';

import FloatingGoToTopButton from './FloatingGoToTopButton';

const MainLayout = () => {
  return (
    <>
      <header>
        <CustomAppBar />
      </header>
      <main>
        <CustomContainer>
          <Outlet />
        </CustomContainer>
        <FloatingGoToTopButton />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default MainLayout;
