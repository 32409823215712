import { useTranslation } from 'react-i18next';
import { Box, Divider, Link } from '@mui/material';
import PropTypes from 'prop-types';

import { Typography } from '~/components/base';

const PrivacyPage = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        px: { xs: '20px', md: 6 },
        py: { xs: 3, sm: '40px', md: 10 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ maxWidth: 1440, width: '100%' }}>
        <Typography
          variant="h2"
          sx={{ textAlign: { xs: 'left', sm: 'center' }, mb: { xs: 0, sm: 5, md: 10 } }}
        >
          {t('privacy.title')}
        </Typography>

        <SectionItem
          title={t('privacy.section-title-1')}
          descriptions={[t('privacy.section-description-1')]}
        />
        <SectionItem
          title={t('privacy.section-title-2')}
          descriptions={[
            t('privacy.section-description-2-1'),
            t('privacy.section-description-2-2'),
            t('privacy.section-description-2-3'),
            t('privacy.section-description-2-4'),
            t('privacy.section-description-2-5'),
          ]}
        />
        <SectionItem
          title={t('privacy.section-title-3')}
          descriptions={[
            t('privacy.section-description-3-1'),
            t('privacy.section-description-3-2'),
            t('privacy.section-description-3-3'),
            t('privacy.section-description-3-4'),
            t('privacy.section-description-3-5'),
          ]}
        />
        <SectionItem
          title={t('privacy.section-title-4')}
          descriptions={[t('privacy.section-description-4')]}
        />
        <SectionItem
          title={t('privacy.section-title-5')}
          descriptions={[t('privacy.section-description-5')]}
        />
        <SectionItem
          title={t('privacy.section-title-6')}
          descriptions={[
            t('privacy.section-description-6-1'),
            t('privacy.section-description-6-2'),
            t('privacy.section-description-6-3'),
          ]}
        />
        <SectionItem
          title={t('privacy.section-title-7')}
          descriptions={[t('privacy.section-description-7')]}
        />
        <SectionItem
          title={t('privacy.section-title-8')}
          descriptions={[t('privacy.section-description-8')]}
        />
        <SectionItem
          title={t('privacy.section-title-9')}
          descriptions={[
            <>
              {t('privacy.section-description-9-1')}{' '}
              <Link sx={{ cursor: 'pointer' }} href="mailto: support@centralive.health">
                {t('privacy.section-description-9-2')}
              </Link>{' '}
              {t('privacy.section-description-9-3')}
            </>,
          ]}
        />
      </Box>
    </Box>
  );
};

const SectionItem = ({ title, descriptions }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: { xs: 5, md: 6 } }}>
      <Typography variant="h4">{title}</Typography>
      <Divider sx={{ display: { xs: 'none', md: 'block' }, my: 0.5 }} />

      <Typography>{descriptions[0]}</Typography>

      {descriptions.length > 1 && (
        <ul
          style={{
            paddingInlineStart: 20,
            margin: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          {descriptions.map(
            (description, index) =>
              index !== 0 && (
                <li key={index}>
                  <Typography>{description}</Typography>
                </li>
              ),
          )}
        </ul>
      )}
    </Box>
  );
};
SectionItem.propTypes = {
  title: PropTypes.string.isRequired,
  descriptions: PropTypes.array.isRequired,
};

export default PrivacyPage;
