import { useEffect, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Zoom } from '@mui/material';

const FloatingGoToTopButton = () => {
  const [show, setShow] = useState(false);

  const handleScroll = () => {
    setShow(window.scrollY > 250);
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Zoom in={show}>
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          height: 40,
          width: 40,
          minWidth: 40,
          borderRadius: '50%',
          zIndex: 9999,
          bgcolor: (theme) => theme.palette.primary.light,
        }}
      >
        <KeyboardArrowUpIcon />
      </Button>
    </Zoom>
  );
};

export default FloatingGoToTopButton;
