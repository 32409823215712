import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { NextImage, Typography } from '~/components/base';

const InformationType1 = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.background.green,
        p: { xs: '48px 20px', sm: '80px 20px', md: '80px 48px' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ maxWidth: 1440 }}>
        <Typography
          sx={{
            textAlign: 'center',
            mb: 6,
          }}
          variant="h3"
        >
          {t('home.InformationType1.title')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '40px',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              height: 82,
              width: 82,
            }}
          >
            <NextImage alt="UCLA" height="100%" name="UCLA" />
          </Box>
          <Box
            sx={{
              position: 'relative',
              height: 82,
              width: 82,
            }}
          >
            <NextImage alt="IRVINE" height="100%" name="IRVINE" />
          </Box>
          <Box
            sx={{
              position: 'relative',
              height: 82,
              width: 85,
            }}
          >
            <NextImage
              alt="the-ohio-state-university"
              height="100%"
              name="TheOhioStateUniversity"
            />
          </Box>
          <Box
            sx={{
              position: 'relative',
              height: 59,
              width: 180,
            }}
          >
            <NextImage alt="university-of-turku" height="100%" name="UniversityOfTurku" />
          </Box>
          <Box
            sx={{
              position: 'relative',
              height: 49,
              width: 166,
            }}
          >
            <NextImage alt="TUWIEN" height="100%" name="TUWIEN" />
          </Box>
          <Box
            sx={{
              position: 'relative',
              height: 49,
              width: 177,
            }}
          >
            <NextImage alt="university-of-miami" height="100%" name="UniversityOfMiami" />
          </Box>
          <Box
            sx={{
              position: 'relative',
              height: 61,
              width: 108,
            }}
          >
            <NextImage alt="purdue-university" height="100%" name="PurdueUniversity" />
          </Box>
          <Box
            sx={{
              position: 'relative',
              height: 54,
              width: 184,
            }}
          >
            <NextImage alt="pepperdine-university" height="100%" name="PepperdineUniversity" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InformationType1;
