import { createTheme } from '@mui/material/styles';

import i18n from '~/services/i18n';

const defaultTheme = createTheme({
  direction: i18n.dir(),
});

const getCommonThemeProps = (isUpMd, locale) => {
  /** @type {import("@mui/material").Theme} */

  return {
    ...defaultTheme,
    typography: {
      ...defaultTheme.typography,
      h1: {
        ...defaultTheme.typography.h1,
        color: '#121212',
        fontSize: isUpMd ? 64 : 48,
        fontWeight: 700,
        lineHeight: '80px',
      },
      h2: {
        ...defaultTheme.typography.h2,
        color: '#121212',
        fontSize: isUpMd ? 60 : 54,
        fontWeight: 800,
        lineHeight: '80px',
      },
      h3: {
        ...defaultTheme.typography.h3,
        color: '#121212',
        fontSize: isUpMd ? 32 : 24,
        fontWeight: 700,
        lineHeight: '36px',
      },
      h4: {
        ...defaultTheme.typography.h4,
        color: '#121212',
        fontSize: isUpMd ? 24 : 20,
        fontWeight: 700,
        lineHeight: '36px',
      },
      h5: {
        ...defaultTheme.typography.h5,
        color: '#121212',
        fontSize: isUpMd ? '1.5rem' : '1.25rem', // 22px - 18px : 24px - 20px
        fontWeight: 700,
        lineHeight: isUpMd ? '2.5rem' : '2rem', // 40px : 40px - 32px
      },
      h6: {
        ...defaultTheme.typography.h6,
        color: '#121212',
        fontSize: isUpMd ? '1.25rem' : '1.125rem', // 20px - 18px
        fontWeight: 300,
        lineHeight: isUpMd ? '2rem' : '1.875rem', // 24px : 32px - 30px
      },
      subtitle1: {
        ...defaultTheme.typography.subtitle1,
        color: '#121212',
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '40px',
      },
      subtitle2: {
        ...defaultTheme.typography.subtitle2,
        color: '#121212',
        fontSize: isUpMd ? 24 : 16,
        fontWeight: 400,
        lineHeight: isUpMd ? '48px' : '28px',
      },
      body1: {
        ...defaultTheme.typography.body1,
        color: '#121212',
        fontSize: '1rem', // 14px : 16px
        fontWeight: 300,
        letterSpacing: '0.02rem',
        lineHeight: '1.5rem', // 24px
      },
      body2: {
        ...defaultTheme.typography.body2,
        color: '#121212',
        fontSize: '0.875rem', // 14px
        fontWeight: 300,
        lineHeight: '1.5rem', //24px
      },
      button: {
        ...defaultTheme.typography.button,
        color: '#121212',
        fontSize: '1rem', // 16px
        fontWeight: 700,
        letterSpacing: '0.02rem',
        lineHeight: '1.5rem', //24px
      },
      caption: {
        ...defaultTheme.typography.caption,
        color: '#121212',
        fontSize: '0.75rem', // 12px
        fontWeight: 300,
        letterSpacing: '0.02rem',
        lineHeight: '1rem', // 16px
      },
      overline: {
        ...defaultTheme.typography.overline,
        color: '#121212',
        fontSize: '0.625rem', // 10px
        fontWeight: 300,
        letterSpacing: '0.05rem',
        lineHeight: '1rem', // 16px
      },
    },
    zIndex: { ...defaultTheme.zIndex, appBar: 1201 },
  };
};

const lightModePalette = {
  ...defaultTheme.palette,
  primary: {
    light: '#FF6154',
    main: '#E6232A',
    dark: '#AB0001',
    contrastText: '#fff',
  },
  background: {
    paper: '#fff',
    default: '#fff',
    gray: '#EAEAEA',
    green: '#E9F9E9',
    lightBlue: 'rgba(204, 212, 255, 0.5)',
  },
  white: {
    light: '#fff',
    main: '#fff',
    dark: '#cccccc',
    contrastText: '#121212',
  },
};

const darkModePalette = {};

const getDesignTokens = ({ mode = 'light', isUpMd, locale = 'en' }) => {
  const palette = {
    mode,
    ...(mode === 'light' ? lightModePalette : darkModePalette),
  };

  return {
    ...getCommonThemeProps(isUpMd, locale),
    palette,
  };
};

export { getDesignTokens };
