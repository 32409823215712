import { CircularProgress as MuiCircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const CircularProgressPropTypes = {
  isCentered: PropTypes.bool,
  sx: PropTypes.object,
};

/** @type {React.FC<import('@mui/material').CircularProgressProps} */
const CircularProgress = ({ isCentered, sx, ...restProps }) => {
  return (
    <MuiCircularProgress
      {...restProps}
      sx={{
        ...(isCentered
          ? {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%)',
            }
          : {}),
        ...sx,
      }}
    />
  );
};

CircularProgress.propTypes = CircularProgressPropTypes;
export default CircularProgress;
