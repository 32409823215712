import PropTypes from 'prop-types';

import { Icon } from '..';

import { StyledButton, StyledCircularProgress, StyledTypography } from './Button.Styles';

const ButtonPropTypes = {
  disabled: PropTypes.bool,
  endIcon: PropTypes.string,
  iconStyle: PropTypes.object,
  label: PropTypes.string,
  labelStyle: PropTypes.object,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.string,
  variant: PropTypes.string,
};

/** @type {React.FC<ButtonPropTypes & Omit<import('@mui/material').ButtonProps, 'startIcon' | 'endIcon'>>} */
const Button = ({
  disabled,
  endIcon,
  iconStyle,
  label,
  labelStyle = {},
  loading,
  onClick,
  startIcon,
  variant = 'contained',
  ...restProps
}) => {
  const icon = startIcon || endIcon;

  const renderLabel = () => {
    if (loading)
      return (
        <StyledCircularProgress labelStyle={labelStyle} size={20} variant={restProps.variant} />
      );

    return <StyledTypography style={labelStyle}>{label}</StyledTypography>;
  };

  const hideIcon = loading;

  return (
    <StyledButton
      disabled={disabled}
      endIcon={
        !endIcon || hideIcon ? null : (
          <Icon alt={endIcon} height={iconStyle.height} name={endIcon} width={iconStyle.width} />
        )
      }
      hasIcon={!!icon}
      labelStyle={labelStyle}
      onClick={!disabled && !loading ? onClick : undefined}
      startIcon={
        !startIcon || hideIcon ? null : (
          <Icon
            alt={startIcon}
            height={iconStyle.height}
            name={startIcon}
            width={iconStyle.width}
          />
        )
      }
      variant={variant}
      {...restProps}
    >
      {renderLabel()}
    </StyledButton>
  );
};

Button.propTypes = ButtonPropTypes;
export default Button;
