import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { NextImage, Typography } from '~/components/base';

const InformationType2 = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.background.lightBlue,
        p: { xs: '24px 20px', sm: '24px 48px', md: '128px 162px' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ maxWidth: 1440 }}>
        <Box
          sx={{
            position: 'relative',
            height: { xs: 56, sm: 64, md: 72 },
            width: { xs: 56, sm: 64, md: 72 },
          }}
        >
          <NextImage alt="logo" height="100%" name="logo" />
        </Box>
        <Typography variant="h2">{t('home.InformationType2.title')}</Typography>
        <Typography sx={{ my: 3 }} variant="h3">
          {t('home.InformationType2.subtitle')}
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          {t('home.InformationType2.description1')}
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          {t('home.InformationType2.description2')}
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          {t('home.InformationType2.description3')}
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          {t('home.InformationType2.description4')}
        </Typography>
      </Box>
    </Box>
  );
};

export default InformationType2;
