import { useEffect, useState } from 'react';
import { faIR as coreFaIR } from '@mui/material/locale';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Constants from '~/constants';
import i18n from '~/services/i18n';

import ThemeContext from './context';
import { getDesignTokens } from './theme';

const DEFAULT_LOCALE = 'fa-IR';
const localeMap = {
  'fa-IR': [coreFaIR],
};

const loadLanguageFromLocalStorage = () => {
  try {
    return localStorage.getItem('i18nextLng');
  } catch (e) {
    console.error('Error with loading language:', e);
    localStorage.setItem('i18nextLng', DEFAULT_LOCALE);
    return DEFAULT_LOCALE;
  }
};
const getInitialTheme = () => {
  try {
    const initialTheme = localStorage.getItem(Constants.THEME.LOCAL_STORAGE_KEY) || 'light';
    localStorage.setItem(Constants.THEME.LOCAL_STORAGE_KEY, initialTheme?.toLowerCase() || 'light');
    return getDesignTokens({
      mode: initialTheme?.toLowerCase() || 'light',
      lang: loadLanguageFromLocalStorage(),
    });
  } catch (e) {
    localStorage.setItem(Constants.THEME.LOCAL_STORAGE_KEY, 'light');
    return getDesignTokens({ mode: 'light', lang: DEFAULT_LOCALE });
  }
};

const ThemeContextProvider = ({ children }) => {
  const [language, setLanguage] = useState(loadLanguageFromLocalStorage);
  const [mode, setMode] = useState('light');
  const [theme, setTheme] = useState(getInitialTheme);

  const syncLanguageWithLocalStorage = () => {
    const newLng = loadLanguageFromLocalStorage();
    setLanguage(newLng);
    i18n.changeLanguage(newLng);
    setTheme(getDesignTokens({ mode, lang: newLng }));
  };
  const toggleTheme = (isDark) => {
    setMode(isDark ? 'dark' : 'light');
    setTheme(getDesignTokens({ mode: isDark ? 'dark' : 'light', lang: language }));
  };

  const muiTheme = createTheme(theme, ...(localeMap[language] || localeMap[DEFAULT_LOCALE]));

  useEffect(() => {
    window.theme = muiTheme;
  }, [muiTheme]);

  useEffect(() => {
    syncLanguageWithLocalStorage();
    window.addEventListener('storage', syncLanguageWithLocalStorage);

    // return () => {
    //   window.removeEventListener('storage', syncLanguageWithLocalStorage);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <ThemeContext.Provider value={toggleTheme}>{children}</ThemeContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeContextProvider;
