import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const CustomContainerPropTypes = {
  children: PropTypes.node,
};

const CustomContainer = ({ children }) => {
  return <Box sx={{ mt: { xs: '56px', md: '64px' } }}>{children}</Box>;
};

CustomContainer.propTypes = CustomContainerPropTypes;
export default CustomContainer;
