import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useStateWithCallback } from '../../../hooks/useStateWithCallback';
import { capitalizeLetter, getCaretPosition } from '../../../utils';

import { StyledDiv, StyledLabel, StyledTextField } from './TextField.Style';

const TextFieldPropTypes = {
  afterInputTag: PropTypes.node,
  containerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.array,
  errorText: PropTypes.string,
  floatingLabel: PropTypes.string,
  helperText: PropTypes.string,
  InputProps: PropTypes.object,
  inputType: PropTypes.string,
  isEmail: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelStyle: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

/** @type {React.ForwardRefRenderFunction<?, import('@mui/material').TextFieldProps & TextFieldPropTypes>} */
const TextField = forwardRef(
  (
    {
      afterInputTag,
      containerStyle,
      disabled,
      error,
      errorText,
      floatingLabel,
      helperText,
      InputProps,
      inputType,
      isEmail,
      isRequired,
      label,
      labelStyle,
      min,
      max,
      name,
      onChange,
      readOnly,
      value,
      ...restProps
    },
    ref,
  ) => {
    const inputRef = useRef(null);
    const { t } = useTranslation([]);

    const [state, setState] = useStateWithCallback({
      beforeRenderVal: '',
      caretPos: 0,
      errorText: errorText || '',
      lastKey: null,
      value: value || '',
    });

    const updateState = (newPartialState, cb) => {
      setState((prevState) => ({ ...prevState, ...newPartialState }), cb);
    };

    useImperativeHandle(ref, () => ({
      checkRequired,
      checkValidation: () => checkValidation(),
      focus: () => {
        inputRef.current.focus();
      },
      getValue: () => {
        const value = !_.isEmpty(inputRef.current?.value) ? inputRef.current.value : '';
        checkRequired(value);
        return value;
      },
      reset: () => {
        inputRef.current.reset();
      },
      setErrorMessage: (errorText) => {
        updateState({ errorText });
      },
      setState: updateState,
      setValue: (value) => {
        updateState({ value });
      },
      state,
    }));

    useEffect(() => {
      if (disabled && isRequired) updateState({ errorText: '' });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled, isRequired]);

    useEffect(() => {
      if (state.value !== value && !_.isUndefined(value)) {
        updateState({ value });
        checkValidation(value, false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.value, value]);

    useEffect(() => {
      if (!state.value) return;
      let timeOutId;

      return () => {
        if (timeOutId) clearTimeout(timeOutId);
      };
    }, [state.value]);

    useEffect(() => {
      updateState({ errorText });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorText]);

    const checkRequired = (val = state.value) => {
      if (isRequired) {
        if (!disabled && (!val || val.length === 0))
          updateState({ errorText: t('validations.generalRequiredField') });
        else if (state.errorText === t('validations.generalRequiredField'))
          updateState({ errorText: '' });
      }
    };
    const hasError = () => {
      if (error.length) {
        const findError = error.find((item) => item.field === name);

        if (findError) return capitalizeLetter(findError.message);
      }
    };
    const convertValue = (value) => {
      if (value && inputType === 'number') return parseInt(value);

      return value;
    };
    const checkValidation = (value = state.value, checkAll = true) => {
      checkRequired(value);
      if (checkAll) checkEmailFormat(value);
    };
    const _onChange = (event) => {
      if (readOnly) return;

      const { target } = event;
      let value;
      if (target) value = target.value;
      else value = event;

      updateState({ errorText: '' });
      checkValidation(value, false);
      //we need to manually getCaretPos to reuse it later in timeout callback
      state.caretPos = getCaretPosition(target);
      //we need to populate value here because it will change in render
      state.beforeRenderVal = value;

      updateState({ value });

      if (typeof onChange === 'function') {
        if (name) onChange({ value: convertValue(value), name });
        else onChange(value);
      }
    };
    const checkEmailFormat = (value) => {
      if (isEmail) {
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (!disabled && value.length && !emailRegex.test(value))
          updateState({
            errorText: t('validations.invalidEmailFormat'),
          });
        else if (state.errorText === t('validations.invalidEmailFormat'))
          updateState({ errorText: '' });
      }
    };

    return (
      <StyledDiv containerStyle={containerStyle} fullWidth={restProps.fullWidth}>
        {!!label && <StyledLabel labelStyle={labelStyle}>{label}</StyledLabel>}
        <StyledTextField
          label={floatingLabel}
          inputRef={inputRef}
          value={state.value}
          helperText={hasError() || helperText || (!disabled && state.errorText)}
          error={hasError() || (!disabled && !!state.errorText)}
          disabled={disabled}
          required={isRequired}
          onChange={_onChange}
          type={inputType}
          InputProps={{
            readOnly,
            form: { autocomplete: 'off' },
            endAdornment: afterInputTag,
            ...(InputProps ? InputProps : {}),
          }}
          inputProps={{
            'aria-label': label || floatingLabel || restProps.placeholder,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          FormHelperTextProps={{ role: 'note' }}
          name={name}
          size="small"
          {...restProps}
        />
      </StyledDiv>
    );
  },
);

TextField.defaultProps = {
  error: [],
  inputType: 'string',
  isRequired: false,
  label: '',
  value: '',
  variant: 'standard',
};

TextField.displayName = 'TextField';
TextField.propTypes = TextFieldPropTypes;
export default TextField;
