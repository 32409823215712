export const capitalizeLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};

export const formatTime = (data) => {
  if (!data) return '';

  return formatAsTime(data);
};

export const isNumber = (string) => {
  const numberRegex = /^[0-9]*$/g;
  return numberRegex.test(string);
};

export const getSearchFiltersQuery = (fields, value) => {
  const url = [];

  for (let index = 0; index < fields.length; index++) {
    const field = fields[index];

    if (!field.isNumber) url.push(`${field.name}=ilike='${value}'`);
    else if (isNumber(value)) url.push(`${field.name}==${value}`);
  }

  return url.join(',');
};

/**
 * Estimates new caret position for Time inputs
 * @param firstPos First position of caret before input changed on keyUp
 * @param firstVal Value exactly after keyUp, before input changed
 * @param finalVal Value after our processing (like adding , . $)
 * @return new estimated/desired caret position
 */
const estimateCaretPositionTime = (lastKey, firstPos, firstVal, finalVal) => {
  let pos = firstPos;
  //exceptions:
  if (finalVal === '') return 0;

  if (firstPos === 2) {
    if (lastKey !== 8) {
      pos++;
    }
  } else if (firstPos === 1 && firstVal.length === 1 && parseInt(firstVal) > 2) {
    //if hh > 2 then we go after :
    pos += 2;
  }

  return pos;
};

/**
 * Set the caret to desired position
 */
const setCaretPosition = (el, caretPos) => {
  let range;
  if (el !== null) {
    if (el.createTextRange) {
      range = el.createTextRange();
      range.move('character', caretPos);
      range.select();
    } else {
      if (el.selectionStart) {
        el.focus();
        window.setTimeout(() => {
          el.setSelectionRange(caretPos, caretPos);
        }, 0);
      } else {
        el.focus();
      }
    }
  }
};

/**
 * HH:MM
 * @param value
 * @return {string|*}
 */
const formatAsTime = (value) => {
  //var ourFormat = 'YYYY.MM.DD';
  let hh = '00';
  let mm = '00';
  let a = [];
  let newUnOriginalValue;

  if (new RegExp(/^[0-9]{1,2}/).test(value)) {
    a = new RegExp(/^([0-9]{1,2})/).exec(value);
    hh = a[1];
  }
  if (new RegExp(/^[0-9]+:[0-9]{1,2}/).test(value)) {
    a = new RegExp(/^[0-9]+:([0-9]{1,2})/).exec(value);
    mm = a[1];
  }

  //fix ranges:
  if (parseInt(hh) > 24) hh = '24';

  if (parseInt(mm) > 59) mm = '59';

  newUnOriginalValue = hh + ':' + mm;
  return newUnOriginalValue;
};

/**
 * Returns the caret (cursor) position of the specified text field.
 * Return value range is 0-el.value.length.
 */
export const getCaretPosition = (el) => {
  // Initialize
  let iCaretPos = 0;
  // IE Support
  if (document.selection) {
    // Set focus on the element
    el.focus();
    // To get cursor position, get empty selection range
    const oSel = document.selection.createRange();
    // Move selection start to 0 position
    oSel.moveStart('character', -el.value.length);
    // The caret position is selection length
    iCaretPos = oSel.text.length;
  } else if (el.selectionStart || el.selectionStart === '0') {
    // Firefox support
    iCaretPos = el.selectionStart;
  }
  // Return results
  return iCaretPos;
};

/**
 * will update caret position based on beforeRender value and caret position
 * @param keyCode pressed keyCode
 * @param target target clicked input
 * @param beforeRenderVal value populated before render
 * @param caretPos caret position before render
 */
export const updateCaretPosForTime = (target, lastKey, beforeRenderVal, caretPos) => {
  let desiredPos;
  desiredPos = estimateCaretPositionTime(lastKey, caretPos, beforeRenderVal, target.value);
  setCaretPosition(target, desiredPos);
};

export const isFilterable = (ref) => !!ref.current?.getValue() && ref.current.getValue() !== 'all';
export const isSearchable = (ref) => !!ref.current?.getValue() && !!ref.current.getValue().length;
export const isSortable = (ref) => !!ref.current?.getValue() && ref.current.getValue() !== 'unSort';
