import { createBrowserRouter } from 'react-router-dom';

import MainLayout from '~/components/layout/mainLayout';
import NotFound from '~/components/layout/notFound';
import HomePage from '~/pages/home';

import PrivacyPage from './pages/privacy';

const router = createBrowserRouter([
  {
    errorElement: <NotFound />,
    children: [
      {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: <HomePage />,
          },
          {
            path: 'privacy',
            element: <PrivacyPage />,
          },
        ],
      },
    ],
  },
]);

export default router;
