import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

import { Logo, Typography } from '~/components/base';
import RequestDemo from '~/components/fullComponent/Sections/RequestDemo';

import { StyledFooter } from './Footer.Style';

const Footer = () => {
  const { t } = useTranslation([]);

  return (
    <StyledFooter>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-end' },
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: 'fit-content',
              height: 'fit-content',
              pr: { xs: 0, md: 12 },
              gap: 24,
            }}
          >
            <Box sx={{ display: { xs: 'none', md: 'block' }, mb: 3 }}>
              <Logo height={48} width={305} />
            </Box>
            <Typography
              sx={{
                textAlign: { xs: 'center', md: 'left' },
              }}
              variant="h4"
            >
              {t('footer.requestDemo')}
            </Typography>
            <Typography sx={{ display: { xs: 'none', md: 'block' }, mt: 3, fontSize: 18 }}>
              {t('footer.description')}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-start' },
            alignItems: 'center',
            pl: { xs: 0, md: 3 },
          }}
        >
          <RequestDemo />
        </Grid>
      </Grid>
    </StyledFooter>
  );
};

export default Footer;
