import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  ReportProblemOutlined,
} from '@mui/icons-material';
import { Alert, Portal, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Typography } from '~/components/base';
import { useSnackbar } from '~/hooks/useSnackbar';

const getTextColor = (severity, theme) => {
  return theme.palette?.[severity]?.dark || theme.palette.info.dark;
};

const useStyles = makeStyles((theme) => ({
  alertRoot: {
    borderRadius: 8,
    backgroundColor: ({ severity }) => getTextColor(severity, theme),
    boxShadow: theme.shadows[24],
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 16px',
  },
  alertMessageWrapper: {
    borderRadius: 8,
    width: '100%',
  },
  alertIconWrapper: {
    margin: 0,
    padding: 0,
    width: 70,
    justifyContent: 'flex-start',
  },
}));

const SnackBarContainer = () => {
  const { snackbarConfig = { severity: 'success' }, hideSnackbar } = useSnackbar();
  const classes = useStyles({ severity: snackbarConfig.severity });

  const getIcon = () => {
    const icons = {
      info: <InfoOutlined sx={{ color: (theme) => theme.palette.common.white }} />,
      success: <CheckCircleOutlineOutlined sx={{ color: (theme) => theme.palette.common.white }} />,
      error: <ErrorOutlineOutlined sx={{ color: (theme) => theme.palette.common.white }} />,
      warning: <ReportProblemOutlined sx={{ color: (theme) => theme.palette.common.white }} />,
    };

    return (
      <>
        {icons[snackbarConfig.severity] || (
          <InfoOutlined sx={{ color: (theme) => theme.palette.common.white }} />
        )}
      </>
    );
  };

  return (
    snackbarConfig.open && (
      <Portal>
        <Snackbar
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          autoHideDuration={snackbarConfig.timeout}
          key={snackbarConfig.key}
          onClose={hideSnackbar}
          open={snackbarConfig.open}
          sx={{ padding: 0, zIndex: 10000 }}
        >
          <Alert
            classes={{
              icon: classes.alertIconWrapper,
              message: classes.alertMessageWrapper,
              root: classes.alertRoot,
            }}
            icon={getIcon()}
            severity={snackbarConfig.severity}
          >
            <Typography sx={{ fontSize: 16, color: (theme) => theme.palette.common.white }}>
              {snackbarConfig.text}
            </Typography>
            <Typography sx={{ fontSize: 12, color: (theme) => theme.palette.common.white }}>
              {snackbarConfig.description}
            </Typography>
          </Alert>
        </Snackbar>
      </Portal>
    )
  );
};

export default SnackBarContainer;
