import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Button, TextField } from '~/components/base';
import { useSnackbar } from '~/hooks/useSnackbar';
import { post } from '~/services/api';

const RequestDemo = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();

  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState();

  const companyInputRef = useRef();
  const emailInputRef = useRef();
  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const messageInputRef = useRef();
  const phoneNumberInputRef = useRef();

  const resetInputs = () => {
    setCompany();
    setFirstName('');
    setEmail('');
    setLastName('');
    setMessage('');
    setPhoneNumber('');
    setTimeout(() => {
      companyInputRef.current.setErrorMessage('');
      emailInputRef.current.setErrorMessage('');
      firstNameInputRef.current.setErrorMessage('');
      lastNameInputRef.current.setErrorMessage('');
      messageInputRef.current.setErrorMessage('');
      phoneNumberInputRef.current.setErrorMessage('');
    }, 10);
  };

  const checkValidation = () => {
    companyInputRef.current.checkRequired();
    emailInputRef.current.checkValidation();
    firstNameInputRef.current.checkValidation();
    lastNameInputRef.current.checkValidation();
    messageInputRef.current.checkValidation();
    phoneNumberInputRef.current.checkValidation();
  };

  const submit = async () => {
    if (
      !!companyInputRef.current?.state.errorText ||
      !!emailInputRef.current?.state.errorText ||
      !!firstNameInputRef.current?.state.errorText ||
      !!lastNameInputRef.current?.state.errorText ||
      !!messageInputRef.current?.state.errorText ||
      !!phoneNumberInputRef.current?.state.errorText
    )
      return;

    let url = `/users/contactus`;
    setLoading(true);

    try {
      await post(url, {
        email,
        firstname: firstName,
        lastname: lastName,
        companyName: company,
        message,
        phone: phoneNumber,
      });
      resetInputs();
      showSnackbar({
        severity: 'success',
        text: 'Your information has been submitted successfully.',
        timeout: 3000,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: { xs: '100%', md: 469 },
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: (theme) => ({
          xs: 'transparent',
          md: theme.palette.background.lightBlue,
        }),
        p: 3,
        borderRadius: '8px',
      }}
      gap={2}
    >
      <TextField
        placeholder={`${t('contactUs.firstName')} *`}
        labelStyle={{ display: 'none' }}
        fullWidth
        isRequired
        onChange={setFirstName}
        ref={firstNameInputRef}
        value={firstName}
        variant="outlined"
      />

      <TextField
        placeholder={`${t('contactUs.lastName')} *`}
        labelStyle={{ display: 'none' }}
        fullWidth
        isRequired
        onChange={setLastName}
        ref={lastNameInputRef}
        value={lastName}
        variant="outlined"
      />

      <TextField
        placeholder={`${t('contactUs.email')} *`}
        labelStyle={{ display: 'none' }}
        fullWidth
        isEmail
        isRequired
        onChange={setEmail}
        ref={emailInputRef}
        value={email}
        variant="outlined"
      />

      <TextField
        placeholder={`${t('contactUs.phone')} *`}
        labelStyle={{ display: 'none' }}
        fullWidth
        isRequired
        onChange={setPhoneNumber}
        ref={phoneNumberInputRef}
        value={phoneNumber}
        variant="outlined"
      />

      <TextField
        placeholder={t('contactUs.company')}
        labelStyle={{ display: 'none' }}
        fullWidth
        onChange={setCompany}
        ref={companyInputRef}
        value={company}
        variant="outlined"
      />

      <TextField
        placeholder={t('contactUs.message')}
        labelStyle={{ display: 'none' }}
        fullWidth
        onChange={setMessage}
        multiline
        minRows={6}
        ref={messageInputRef}
        value={message}
        variant="outlined"
      />

      <Button
        label={t('contactUs.submit')}
        labelStyle={{ color: '#fff' }}
        loading={loading}
        onClick={() => {
          checkValidation();
          setTimeout(() => {
            submit();
          });
        }}
        sx={{
          width: { xs: '100%', md: 204 },
          background: 'linear-gradient(72.59deg, #0875D9 47.88%, #32CD32 88.49%)',
          ':hover': {
            background: 'linear-gradient(72.59deg, #004aa7 47.88%, #009b00 88.49%)',
          },
        }}
      />
    </Box>
  );
};

export default RequestDemo;
